<template>
    <Layout>
        <main :class="$style.page">
            <template
                v-for="(category, index) in preparedCategories"
                :key="category.name"
            >
                <GameCarousel
                    :title="category.title"
                    :link="category.link"
                    :games="category.games"
                    :big="category.isBig"
                    :firstItemBig="category.firstItemBig"
                    :lastItemLink="category.link"
                />
                <PromoCategories  v-if="index === 3" />
            </template>
            <TheEnd/>
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import {
    onServerPrefetch,
    onMounted,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCarousel from '@/components_new/GameCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { categoriesTranslation } from '@/utils/translations/categories'
import { CategoryListType } from '@/types'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: process.env.VUE_APP_HOST_URL,
        },
    ],
})

function getTitle(categoryName: string) {
    return t(categoryName)
}

function getLink(categoryName: string) {
    return `/category/${categoryName}/`
}

function prepareCategories(categories: CategoryListType[]) {
    return categories.map((category, index) => {
        const isBig = index % 2 === 0 && index > 0
        const firstItemBig = index === 0

        let games = category.games?.slice(0)
        if (!isBig && games) {
            if (
                (firstItemBig && games.length % 2 === 0)
                || (!firstItemBig && games.length % 2 === 1)
            ) {
                games = games.slice(0, -1)
            }
        }
        return {
            name: category.name,
            title: getTitle(category.name),
            link: getLink(category.name),
            games,
            isBig,
            firstItemBig,
        }
    })
}

const categoriesStore = useCategoriesStore()

const {
    contentCategoriesList,
} = storeToRefs(categoriesStore)

const preparedCategories = computed(() => prepareCategories(contentCategoriesList.value))

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
</style>
