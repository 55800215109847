<template>
    <div :class="$style.header">
        <div :class="$style.leftBlock">
            <div :class="$style.menu">
                <div :class="$style.short_menu">
                    <template v-if="categoriesModalOpened">
                        <Button @click="menuToggle" iconLeft="close" visual="text">
                            {{ t('close') }}
                        </Button>
                    </template>
                    <template v-else>
                        <button @click="menuToggle" style="display: flex; padding: 0">
                            <Icon name="menu" size="m" />
                        </button>
                        <Logo :class="$style.logo" short/>
                    </template>
                </div>
                <div :class="$style.wide_menu">
                    <Logo :class="$style.logo"/>
                    <Button @click="menuToggle" :class="$style.menu_button" iconLeft="menu" visual="text" size="l">{{ t('all_games') }}</Button>
                </div>
            </div>
        </div>
        <div :class="$style.spacer"/>
        <div v-if="isUserInfoLoaded" :class="$style.rightBlock">
            <template v-if="isAuthorized && user">
                <RouterLink to="/profile" :class="$style.coins">
                    <Icon name="fennek_coin" size="m" :class="$style.coins_icon"/>
                    {{ fennecsLabel }}
                </RouterLink>
                <RouterLink to="/profile">
                    <UserAvatar
                        :class="$style.avatar"
                        :avatar="user.avatar"
                        :firstName="user.firstName"
                        :lastName="user.lastName"
                    />
                </RouterLink>
            </template>
            <template v-else>
                <Button :class="$style.login" size="m" effect="bevel" @click="signin">{{ t('login') }}</Button>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { storeToRefs } from 'pinia'
import Logo from '@/components_new/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Button from '@/components_new/Button.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import { useUserStore } from '@/store/user-store'
import { useAppStore } from '@/store/app-store'
import { useLayoutStore } from '@/store/layout-store'

const { t } = useI18n()

const { setIsAuthUserModalOpen } = useAppStore()

const userStore = useUserStore()
const {
    user, /* taskStats, */ isAuthorized, fennecsLabel, isUserInfoLoaded,
} = storeToRefs(userStore)

function signin() {
    setIsAuthUserModalOpen(true)
}

const layoutStore = useLayoutStore()
const { categoriesModalOpened } = storeToRefs(layoutStore)
function menuToggle() {
    layoutStore.setCategoriesModalOpened(!categoriesModalOpened.value)
}
</script>
<style module>

.header {
    background: #17171A;
    height: var(--global-header-pannel-height);
    padding-left: calc(var(--global-padding-horizontal) + env(safe-area-inset-left));
    padding-right: calc(var(--global-padding-horizontal) + env(safe-area-inset-right));
}

.header, .leftBlock, .rightBlock, .coins, .wide_menu, .short_menu {
    display: flex;
    align-items: center;
}

.menu {
}

.leftBlock {
    gap: 16px;
}

.wide_menu {
    gap: 16px;
}

.wide_menu .logo {
    width: 120px;
    height: 24px;
}

.short_menu {
    color: #C7ABFF;
    gap: 8px;
    display: none;
}

.short_menu .logo {
    width: 28px;
    height: 24px;
}

.spacer {
    flex-grow: 1;
}

.rightBlock {
    gap: 12px;
}

.coins {
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;
    gap: 8px;
}

.avatar {
    width: 32px;
}

@media (--mobile) {
    .wide_menu {
        display: none;
    }

    .short_menu {
        display: flex;
    }

    .rightBlock {
        gap: 8px;
    }

    .coins {
        font-size: 16px;
        gap: 6px;
    }

    .coins_icon {
        width: 20px;
    }

    .avatar {
        width: 24px;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "all_games": "All games",
        "login": "Login & Get coins",
        "close": "Close"
    }
}</i18n>
