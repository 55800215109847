<template>
    <RouterView />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'App',
})
</script>

<style>
   @import '@/assets/styles/index.css';

    body {
        height: 100%;
        background-color: var(--background);
        color: var(--white);
        font-family: var(--font-primary);
        scrollbar-gutter: stable;
    }

    #app {
        height: 100%;
    }
</style>
