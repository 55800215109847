import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead, VueHeadMixin } from '@unhead/vue'
import getRouter from '@/router'
import FloatingVue from 'floating-vue'
import FloatingThemes from '@/components_new/Floating/floating-themes'
import App from './App.vue'
import i18n from './i18n'

const pinia = createPinia()
const head = createHead()
const router = getRouter(false)
pinia.state.value = window.__pinia || {}
createApp(App)
    .use(i18n)
    .use(router)
    .use(pinia)
    .use(head)
    .use(FloatingVue, FloatingThemes)
    .mixin(VueHeadMixin)
    .mount('#app')
