<template>
    <dialog ref="dialogRef" @cancel="requestCloseModal" :class="$style.modal">
        <div :class="$style.mobile_header">
            <MobileHeader v-if="categoriesModalOpened"/>
        </div>
        <Transition
            :enter-from-class="$style.transition_enter_from"
            :enter-active-class="$style.transition_enter_active"
            :enter-to-class="$style.transition_enter_to"
            :leave-from-class="$style.transition_leave_from"
            :leave-active-class="$style.transition_leave_active"
            :leave-to-class="$style.transition_leave_to"
            @before-enter="openModal"
            @after-leave="closeModal"
            appear
        >
            <div v-if="categoriesModalOpened" :class="$style.modalBody">
                <Button @click="requestCloseModal" icon="close" color="graphite" :class="$style.close"/>
                <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                <div :class="$style.scroller" @click="outsideClick" >
                    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                    <div :class="$style.grid_wrap" @click="outsideClick" >
                        <div :class="$style.grid">
                            <div :class="$style.item_dynamic_hover" ref="itemDynamicHover"/>
                            <template v-for="(item) in menuCategoriesList" :key="item.id">
                                <RouterLink
                                    :to="item.href"
                                    :class="$style.item"
                                    @click="requestCloseModal"
                                    @mouseenter="itemMouseEnterOrFocus"
                                    @mousemove="itemMouseMove"
                                    @focus="itemMouseEnterOrFocus"
                                >
                                    <Icon :name="category2icon[item.name]" :class="$style.item_icon"/>
                                    <div :class="$style.item_text">{{ t(item.name) }}</div>
                                </RouterLink>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import MobileHeader from '@/components_new/MobileHeader.vue'
import { category2icon } from '@/components_new/Icon/categories'
import { useCategoriesStore } from '@/store/categories-store'
import { useLayoutStore } from '@/store/layout-store'
import { categoriesTranslation } from '@/utils/translations/categories'
import { useDynamicHover } from './dynamicHover'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const layoutStore = useLayoutStore()
const { setCategoriesModalOpened } = layoutStore

const dialogRef = ref<HTMLDialogElement | null>(null)

const { categoriesModalOpened } = storeToRefs(layoutStore)

// open modal before animation starts
function openModal() {
    dialogRef.value?.showModal()
}
// close modal after exit animation
function closeModal() {
    dialogRef.value?.close()
}

// trigger exit animation
function requestCloseModal(e: Event) {
    e.preventDefault()
    setCategoriesModalOpened(false)
}

function outsideClick(e: MouseEvent) {
    if (e.target === e.currentTarget) {
        requestCloseModal(e)
    }
}

const categoriesStore = useCategoriesStore()

const {
    menuCategoriesList,
} = storeToRefs(categoriesStore)

const {
    itemDynamicHover,
    updateItemDynamicHoverPositionDebounced,
    updateItemDynamicHoverPosition,
} = useDynamicHover()

function itemMouseEnterOrFocus(e: (MouseEvent | KeyboardEvent) & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    if ('pageX' in e) {
        updateItemDynamicHoverPositionDebounced({
            pageX: e.pageX,
            pageY: e.pageY,
            currentTarget,
        })
    } else {
        updateItemDynamicHoverPositionDebounced({ currentTarget })
    }
}

function itemMouseMove(e: MouseEvent & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    updateItemDynamicHoverPosition({ pageX: e.pageX, pageY: e.pageY, currentTarget })
}
</script>
<style module>
.modal {
    max-width: 100vw;
    max-height: 100dvh;
    width: 100vw;
    top: 0;
    height: 100dvh;
    overflow: hidden;
}

.modal::backdrop {
    display: none;
}

.mobile_header {
    display: none;
}

.close {
    position: absolute;
    top: 12px;
    left: 12px;
}

.scroller {
    height: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    overflow-x: clip;
    overflow-y: auto;
}

.grid_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.grid {
    display: grid;
    width: 100%;
    max-width: 960px;
    gap: 0 16px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    position: relative;
}

@media (--mobile) {
    .modal[open] {
        display: flex;
        flex-direction: column;
    }

    .mobile_header {
        display: block;
        height: var(--global-header-pannel-height);
        flex-shrink: 0;
    }

    .modalBody {
        background: #17171A;
        overflow: hidden;
    }

    .transition_enter_from.modalBody,
    .transition_leave_to.modalBody
    {
        opacity: 0;
    }

    .transition_enter_active.modalBody,
    .transition_leave_active.modalBody {
        pointer-events: none;
        transition: all .3s ease;
    }

    .modalBody:before {
        content: '';
        display: block;
        margin: 0 24px;
        border-top: 1px solid rgba(255, 255, 255, .08);
    }

    .close {
        display: none;
    }

    .grid_wrap {
        align-items: flex-start;
    }
}

@media (--tablet-plus) {
    .modalBody {
        height: 100%;
        background-color: rgba(0, 0, 0, .84);
        backdrop-filter: blur(20px);
    }

    .transition_enter_from.modalBody,
    .transition_leave_to.modalBody
    {
        opacity: 0;
        transform: scale(1.15);
        filter: blur(20px);
    }

    .transition_enter_active.modalBody,
    .transition_leave_active.modalBody {
        pointer-events: none;
        transition: all .3s cubic-bezier(.4,0,.2,1);
    }

    .grid {
        width: 70%;
    }
}

.item {
    padding: 16px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background-color: transparent;
    transition: background-color .1s;
    z-index: 0;
    outline: none;
}

@media (hover: none) {
    .item:active {
        background-color: #9747FF;
    }
}

.item_icon {
    width: 28px;
    height: 28px;
}
.item_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
}

.item_dynamic_hover {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    background-color: #9747FF;
    width: 30px;
    height: 30px;
    z-index: 0;

    visibility: hidden;
    opacity: 0;
    transition: opacity .1s ease, /* transform .1s ease, */ visibility 0s ease .1s;
}

.grid:hover .item_dynamic_hover,
.grid:focus-within .item_dynamic_hover {
    visibility: visible;
    opacity: 1;
    transition: opacity .1s ease, /* transform .1s ease, */ visibility 0s ease;
}
</style>
