<template>
    <div :class="$style.container">
        <ul :class="$style.breadcrumbs">
            <li v-for="(item, index) in breadcrumbs" :key="item.title">
                <span v-if="index !== 0" :class="$style.breadcrum_item">/</span>
                <component
                    :is="item.link ? RouterLink : 'span'"
                    :to="item.link"
                    :class="$style.breadcrum_item"
                >
                    {{ item.title }}
                </component>
            </li>
        </ul>
        <h1 :class="$style.title">{{ props.category?.name }}</h1>
        <p v-if="props.category?.description" :class="$style.description">{{ props.category?.description }}</p>
    </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const props = defineProps<{
    category: {
        id: string;
        name: string;
        description?: string;
    } | null;
}>()

const breadcrumbs = computed(() => ([
    {
        title: 'Playgama',
        link: '/',
    },
    {
        title: t(props.category?.id || ''),
    },
]))
</script>
<style module>
.container {
    display: flex;
    padding: 16px 16px 24px 16px;

    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    border-radius: 16px;
    background: #17171A;
}

.breadcrumbs {
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 8px;

    color: rgba(255, 255, 255, 0.4);
}

.breadcrumbs li {
    display: contents;
}

.breadcrum_item {
    padding: 8px 2px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 100%;
}

.description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    margin-top: 12px;
    color: rgba(255, 255, 255, 0.8);
}
</style>
