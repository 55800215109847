<template>
    <div :class="$style.avatar">
        <span :class="$style.placeholder">{{ avatarPlaceholder }}</span>
        <div v-if="props.avatar" :style="{backgroundImage: `url(${props.avatar})`}" :class="$style.img" />
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    defineProps,
} from 'vue'

type UserAvatarProps = {
    avatar?: string,
    firstName: string,
    lastName: string,
}

const props = defineProps<UserAvatarProps>()

const avatarPlaceholder = computed(() => `${props.firstName[0].toUpperCase()}${props.lastName[0].toUpperCase()}`)

</script>
<style module>
.avatar {
    width: 24px;
    aspect-ratio: 1;
    position: relative;
    border-radius: 1000px;
    overflow: hidden;
    outline: 1px solid rgba(255, 255, 255, .4);
}

.placeholder, .img {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder {
    background: #29292E;
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
}

.img {
    background-size: contain;
}
</style>
