import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { GameType, GameRecommendation } from '@/types'
import { getGameByParam, getGameRecommendations } from '@/utils/requests/games'

type GamePagerecommendations = GameRecommendation<['recommended', 'top_playgama', 'related', 'random']>
export const useGamePageStore = defineStore('gamePage', () => {
    const game = ref<GameType | undefined>(undefined)
    const recommendations = ref<GamePagerecommendations | undefined>(undefined)

    function setGame(param: string): Promise<GameType> {
        return getGameByParam(param).then((data) => {
            game.value = data
            return data
        })
    }

    function setGameWithRecommendations(param: string): Promise<[GameType, GamePagerecommendations]> {
        return Promise.all([
            setGame(param),
            getGameRecommendations(param, ['recommended', 'top_playgama', 'related', 'random']).then((data) => {
                recommendations.value = data
                return data
            }),
        ])
    }

    function resetGameData(): void {
        game.value = undefined
        recommendations.value = undefined
    }

    return {
        game,
        recommendations,
        setGame,
        setGameWithRecommendations,
        resetGameData,
    }
})
