<template>
    <div :class="[$style.container, { [$style.fullscreen]: isFullscreen }]">
        <div :class="$style.fullscreen_pannel">
            <Logo :class="$style.fullscreen_pannel_logo" short noLink />
            <div :class="$style.pannel_spacer" />
            <Button visual="outline" @click="toggleFullscreen()">
                {{ t('exit_fullscreen') }}
            </Button>
        </div>
        <div :class="$style.fullscreen_pannel_compact">
            <Icon name="close" :class="$style.fullscreen_pannel_compact_close" @click="toggleFullscreen()"/>
        </div>
        <div :class="$style.content">
            <template v-if="game">
                <template  v-if="isGameStarted">
                    <GameFrame :class="$style.iframe" :title="game.title" :game-src="gameSrc" />
                    <RotateMessage :orientation="game.orientation" />
                    <template v-if="!isFullscreen && platform !== 'desktop'">
                        <div :class="$style.paranja"/>
                        <div :class="$style.cover">
                            <Button size="l" width="full" visual="outline" color="white" @click="toggleFullscreen()">{{ t('continue_play')
                            }}</Button>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div :class="$style.background" :style="{ backgroundImage: `url(${game.preview})` }"></div>
                    <div :class="$style.cover">
                        <img :class="$style.preview" :src="game.preview" :alt="game.title" />
                        <div :class="$style.cover_buttons">
                            <template v-if="isUserInfoLoaded && !isAuthorized">
                                <Button size="l" width="full" effect="bevel" color="golden" iconLeft="fennek_coin" @click="handlePlayAndEarn">
                                    {{ t('playAndEarn') }}
                                </Button>
                                <Button size="l" width="full" visual="outline" color="white" @click="handlePlay">
                                    {{ t('playNoAuth') }}
                                </Button>
                            </template>
                            <template v-else>
                                <Button size="l" width="full" effect="bevel" color="golden" @click="handlePlay">{{ t('play')
                                }}</Button>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <div :class="$style.footer">
            <template v-if="props.game">
                <h1 :class="$style.title">{{ props.game.title }}</h1>
                <div :class="$style.pannel_spacer" />
                <Button :class="$style.report_button_wide" visual="text" color="graphite" iconLeft="report" @click="handleReport">
                    {{ t('report') }}
                </Button>
                <Button :class="$style.report_button_short"  visual="text" color="graphite" icon="report" @click="handleReport"/>
                <div style="position: relative;">
                    <Button v-tooltip="shareCopiedTooltip" visual="text" color="graphite" iconLeft="share" @click="handleShare">{{t('share')}}</Button>
                </div>
                <template v-if="platform === 'desktop'">
                    <Button v-if="!isFullscreen" visual="text" color="graphite" iconLeft="fullscreen" @click="toggleFullscreen()">
                        {{ t('fullscreen') }}
                    </Button>
                    <Button v-else visual="text" color="graphite" iconLeft="collapse" @click="toggleFullscreen()">
                        {{ t('fullscreen') }}
                    </Button>
                </template>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    defineEmits,
    defineProps,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import Logo from '@/components_new/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { GameType } from '@/types'
import { useUserStore } from '@/store/user-store'
import { useAppStore } from '@/store/app-store'
import { useLayoutStore } from '@/store/layout-store'
import GameFrame from '../components/Frame.vue'
import RotateMessage from './RotateMessage/RotateMessage.vue'

const { t } = useI18n()
const userStore = useUserStore()
const { setIsAuthUserModalOpen, platform } = useAppStore()

const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

interface GameDetailsModalProps {
    game?: GameType
    isGameStarted: boolean
}

const props = defineProps<GameDetailsModalProps>()

const gameSrc = computed(() => {
    if (!props.game) {
        return ''
    }
    const url = new URL(props.game.game_url)
    url.searchParams.set('utm_source', 'parent_window_api')
    url.searchParams.set('flags', '{"adv_types_disabled":16}')

    return url.href
})

const isFullscreen = ref<boolean>(false)

const layoutStore = useLayoutStore()
const showCopied = ref(false)

watch(() => isFullscreen.value, (value) => {
    layoutStore.setFullscreen(value)
})

const shareCopiedTooltip = computed(() => ({
    content: t('link_copied'),
    theme: 'common-tooltip',
    shown: showCopied.value,
    triggers: [],
}))
function toggleFullscreen(forceFullscreen?: boolean) {
    if (typeof forceFullscreen === 'boolean') {
        isFullscreen.value = forceFullscreen
    } else {
        isFullscreen.value = !isFullscreen.value
    }
    if (document.fullscreenEnabled) {
        if (isFullscreen.value) {
            document.body.requestFullscreen()
        } else {
            document.exitFullscreen()
        }
    }
}

/* eslint-disable no-unused-vars */
/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
const emit = defineEmits<{
    (e: 'startPlayingGame' | 'openReportModal'): void
}>()

function handlePlay() {
    if (platform !== 'desktop') {
        toggleFullscreen(true)
    }
    emit('startPlayingGame')
}

function handlePlayAndEarn() {
    setIsAuthUserModalOpen(true)
}

function handleReport() {
    emit('openReportModal')
}

function handleShare() {
    if (!props.game) {
        return
    }
    const data = {
        title: props.game?.title,
        text: props.game?.description,
        url: window.location.href, // TODO add query param to indicate sharing
    }
    if (platform !== 'desktop' && navigator.canShare && navigator.canShare(data)) {
        navigator.share(data)
    } else {
        navigator.clipboard.writeText(data.url).then(
            () => {
                showCopied.value = true
                setTimeout(() => {
                    showCopied.value = false
                }, 3000)
            },
            () => {
                console.error('Failed to copy text')
            },
        )
    }
}

const onFullscreenChange = () => {
    isFullscreen.value = document.fullscreenElement !== null
}
onMounted(() => {
    if (document.fullscreenEnabled) {
        document.addEventListener('fullscreenchange', onFullscreenChange)
    }
})

onUnmounted(() => {
    document.removeEventListener('fullscreenchange', onFullscreenChange)
    layoutStore.setFullscreen(false)
})

</script>

<style module>
.container {
    background: #000;
    --cotainer-border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.content {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: var(--cotainer-border-radius) var(--cotainer-border-radius) 0 0;
}

.content > * {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.background {
    background-size: cover;
    background-position: center;
    transform: scale(1.5);
    filter: blur(30px) brightness(0.4);
    pointer-events: none;
}

.cover {
    width: min-content;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
}

.paranja {
    background: rgba(0, 0, 0, 0.5);
}

.preview {
    width: 220px;
    height: 132px;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
}

.cover_buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.iframe {
    object-fit: contain;
}

.footer {
    background-color: #17171a;
    padding: 12px 16px;
    height: calc(32px + 12px * 2);
    box-sizing: border-box; /* TODO fix normalizr in dev mde */
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 var(--cotainer-border-radius) var(--cotainer-border-radius);
}

.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pannel_spacer {
    flex-grow: 1;
}

.report_button_short {
    display: block;
}
.report_button_wide {
    display: none;
}

.fullscreen {
    --cotainer-border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100dvh;
    z-index: 1;
}

.fullscreen * {
    touch-action: manipulation;
}

.fullscreen .content {
    flex-grow: 1;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
}

.fullscreen_pannel {
    display: none;
    align-items: center;
    background: #000;
    padding: 0 12px;
    height: 48px;
    box-sizing: border-box;
}

.fullscreen_pannel_logo {
    height: 26px;
    width: 28px;
}

.fullscreen_pannel_compact {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #29292E;
    border-radius: 0 0 16px 0;
    padding: 16px 12px;
}

.fullscreen_pannel_compact_close {
    width: 16px;
    height: 16px;
}

@media (--mobile) {
    .container {
        --cotainer-border-radius: 0;
       margin: calc(var(--global-page-top-padding) * -1) calc(var(--global-padding-horizontal) * -1) 0;
    }

   .container.fullscreen {
      margin: 0;
    }

    .content {
        height: 344px;
    }

    .cover_buttons {
        flex-direction: column;
        gap: 12px;
    }

    .fullscreen .fullscreen_pannel {
        display: flex;
    }

    .fullscreen .footer {
        display: none;
    }
}

@media (--mobile-landscape) {

    .fullscreen .fullscreen_pannel {
        display: none;
    }

    .fullscreen .fullscreen_pannel_compact {
        display: flex;
    }
}

@media (--tablet-plus) {
    .content {
        aspect-ratio: 16 / 9;
        min-height: 464px;
        height: auto;
    }
}

@media (--desktop-small-plus) {
    .preview {
        width: 448px;
        height: 272px;
    }

    .cover_buttons {
        flex-direction: row;
        gap: 16px;
    }

    .report_button_short {
        display: none;
    }
    .report_button_wide {
        display: block;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "play": "Play now",
        "playNoAuth": "Just Play",
        "playAndEarn": "Play & Earn",
        "continue_play": "Continue play",
        "like": "Like",
        "share": "Share",
        "link_copied": "Link copied!",
        "report": "Report",
        "fullscreen": "Fullscreen",
        "exit_fullscreen": "Exit game"
    }
}</i18n>
