<template>
    <div :class="$style.details">
        <h2 :class="$style.title">{{ props.game.title }}</h2>
        <div :class="$style.meta">
            <div :class="$style.meta__item">
                <span :class="$style.meta__item_key">{{ t('platforms') }}: </span>
                <span :class="$style.meta__item_value">{{ gamePlatforms }}</span>
            </div>
            <div :class="$style.meta__item">
                <span :class="$style.meta__item_key">{{ t('categories') }}: </span>
                <span :class="$style.meta__item_value">
                    <RouterLink
                        v-for="(category, index) in gameCategories"
                        :key="category"
                        :to="`/category/${category}`"
                    >
                        {{ t(category) }}{{ index !== gameCategories.length - 1 ? ', ' : '' }}
                    </RouterLink>
                </span>
            </div>
            <div :class="$style.meta__item">
                <span :class="$style.meta__item_key">{{ t('tags') }}: </span>
                <span :class="$style.meta__item_value">{{ gameTags }}</span>
            </div>
        </div>
        <div :class="$style.hr" />
        <div :class="[$style.description, descriptionExpaneded ? $style.description_expanded : undefined]"
             v-html="gameDescription" ref="descriptionDiv"></div>
        <Button v-if="!descriptionExpaneded" :class="$style.showFullDescButton" color="graphite" @click="onCutClick">{{ t('showFullDesc')
        }}</Button>
    </div>
</template>
<script lang="ts" setup>
import {
    computed, onMounted, ref,
    defineProps,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { GameType } from '@/types'
import Button from '@/components_new/Button.vue'
import { categoriesTranslation } from '@/utils/translations/categories'
import { RouterLink } from 'vue-router'

interface GameCardProps {
    game: GameType,
}

const props = defineProps<GameCardProps>()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const gameDescription = computed(() => {
    let text = `${props.game.description}`
    if (props.game.seoText) {
        text += `\n\n\n\n${props.game.seoText}`
    }
    text = text.replace(/\n•(.+)/gm, '<li>$1</li>')
    text = text.replace(/(<li>.+<\/li>)+/g, '<ul>$1</ul>')
    text = text.replace(/<p><\/p>/g, '')
    text = text.replace(/\n\n/g, '<br>')
    text = text.replace(/<(\/?)h2>/g, '<$1h6>')
    return text
})

const descriptionDiv = ref<HTMLElement | null>(null)
const descriptionExpaneded = ref<boolean>(false)

const gamePlatforms = computed(() => {
    /* eslint-disable-next-line camelcase  */
    const { is_ios, is_android, is_desktop } = props.game || {}
    /* eslint-disable-next-line camelcase  */
    const platformsValues = { ios: is_ios, android: is_android, desktop: is_desktop }
    const res = Object.entries(platformsValues)
        .filter(([, value]) => value)
        .map(([key]) => t(key))
        .join(', ')
    return res
})

const gameTags = computed(() => {
    const text = props.game.tags.slice(0, 5).join(', ')
    return text
})
const gameCategories = computed(() => props.game.categories.slice(0, 5))

function onCutClick() {
    descriptionExpaneded.value = true
}

onMounted(() => {
    if (!descriptionDiv.value) return
    const descriptionClientHeight = descriptionDiv.value.clientHeight
    const descriptionScrollHeight = descriptionDiv.value.scrollHeight
    const descriptionLineHeight = parseInt(window.getComputedStyle(descriptionDiv.value).lineHeight, 10)
    const diff = descriptionScrollHeight - descriptionClientHeight
    if (diff / descriptionLineHeight > 5) {
        descriptionExpaneded.value = false
    } else {
        descriptionExpaneded.value = true
    }
})

</script>
<style module>
.details {
    border-radius: 16px;
    background: #17171A;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;
}

.meta {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 16px;
}

.meta__item_key {
    color: rgba(255, 255, 255, 0.4);
}

.meta__item_value {
    color: rgba(255, 255, 255, 0.8);
}

.hr {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    color: rgba(255, 255, 255, 0.8);

    overflow: hidden;
    display: -webkit-box;
    --line-clamp: 3;
    -webkit-line-clamp: var(--line-clamp);
    line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
}

.description_expanded {
    -webkit-line-clamp: unset;
    line-clamp: unset;
}

.description ul {
    list-style-type: disc;
    list-style-position: inside;
}

.description h6 {
    font-size: 23px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 4px;
}

.showFullDescButton {
    align-self: flex-start;
}

@media (--mobile) {
    .description {
        --line-clamp: 6;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "desktop": "Desktop",
        "android": "Android",
        "ios": "iOS",
        "platforms": "Platforms",
        "categories": "Categories",
        "tags": "Tags",
        "showFullDesc": "Show all description"
    }
}</i18n>
