<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
    <component
        :is="props.game ? RouterLink : 'div'"
        :to="gameLink"
        :class="[$style.gameCard, props.responsive && $style.responsive, props.appearance === 'big' && $style.bigCard]"
        itemscope
        itemtype="https://schema.org/VideoGame"
        itemprop="url"
        @mouseenter="showVideo"
        @focus="showVideo"
    >
        <img
            v-if="props.game"
            :alt="props.game.title"
            :src="props.game.preview"
            :class="$style.img"
            :srcset="srcset"
            itemprop="image"
            loading="lazy"
            width="1"
            height="1"
        />
        <video
            v-if="isVideoAllowed && props.game?.video"
            :class="$style.video"
            :src="props.game.video.preview_url"
            loop
            muted
            playsInline
            autoplay
            itemprop="video"
        />
        <span v-if="props.game" itemprop="name" :class="$style.title">{{ props.game.title }}</span>
    </component>
</template>

<script setup lang="ts">
import {
    defineProps,
    computed,
    ref,
} from 'vue'
import { RouterLink } from 'vue-router'
import type { SimplifiedGame } from '@/types'

interface GameCardProps {
    game?: SimplifiedGame,
    appearance: 'big'|'medium',
    responsive?: boolean,
}

const props = defineProps<GameCardProps>()

const isVideoAllowed = ref(false)
const gameLink = computed(() => `/game/${props.game?.hru}`)

function showVideo() {
    isVideoAllowed.value = true
}

function transformSrcSet(input?: string) {
    if (!input) return ''
    switch (props.appearance) {
    case 'big':
        return input
    case 'medium':
    default:
        return `${input.replace('enlarged', 'medium')},
            ${input} 2x`
    }
}

const srcset = computed(() => transformSrcSet(props.game?.preview))
</script>

<style module>
.gameCard {
    display: block;
    width: 220px;
    height: 132px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    background-color: #17171A;
}
.responsive {
    width: 100%;
    aspect-ratio: 220/132;
    height: auto;
}

.bigCard.responsive {
    /* same as normal, but with additional 8px gaps */
    aspect-ratio: calc(220 + var(--cards-gap))/calc(132 + var(--cards-gap));
}

.img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title, .video {
    display: none;
}

.gameCard:hover .video {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.gameCard:hover .title {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 12px 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 2;
}
</style>
