<template>
    <img v-if="typeof icon === 'string'" :src="icon" :alt="altText" :class="[$style.icon, $style.img]" />
    <component v-else :class="$style.icon" :is="icon" role="img" :aria-label="altText" />
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'

// category icons
import Action from './categories-icons/action.svg'
import Adventure from './categories-icons/adventure.svg'
import AllGames from './categories-icons/all_games.svg'
import Applications from './categories-icons/applications.svg'
import Baby from './categories-icons/baby.svg'
import Baloons from './categories-icons/baloons.svg'
import Bejeweled from './categories-icons/bejeweled.svg'
import Boys from './categories-icons/boys.svg'
import Cards from './categories-icons/cards.svg'
import Casino from './categories-icons/casino.svg'
import Clicker from './categories-icons/clicker.svg'
import CompleteReg from './categories-icons/complete_reg.svg'
import Cook from './categories-icons/cook.svg'
import Economic from './categories-icons/economic.svg'
import Educational from './categories-icons/educational.svg'
import Farming from './categories-icons/farming.svg'
import Girls from './categories-icons/girls.svg'
import Horrors from './categories-icons/horrors.svg'
import Hypercasual from './categories-icons/hypercasual.svg'
import Imitations from './categories-icons/imitations.svg'
import Io from './categories-icons/io.svg'
import Kids from './categories-icons/kids.svg'
import Match3 from './categories-icons/match3.svg'
import Midcore from './categories-icons/midcore.svg'
import New from './categories-icons/new.svg'
import Popular from './categories-icons/popular.svg'
import Purchase from './categories-icons/purchase.svg'
import Puzzle from './categories-icons/puzzle.svg'
import Quiz from './categories-icons/quiz.svg'
import Racing from './categories-icons/racing.svg'
import Random from './categories-icons/random.svg'
import Recommended from './categories-icons/recommended.svg'
import Role from './categories-icons/role.svg'
import Shooting from './categories-icons/shooting.svg'
import Simulator from './categories-icons/simulator.svg'
import Soccer from './categories-icons/soccer.svg'
import Social from './categories-icons/social.svg'
import Special from './categories-icons/special.svg'
import Sports from './categories-icons/sports.svg'
import Stickman from './categories-icons/stickman.svg'
import Strategy from './categories-icons/strategy.svg'
import Tabletop from './categories-icons/tabletop.svg'
import Tests from './categories-icons/tests.svg'
import ThreeD from './categories-icons/three_d.svg'
import TrendingTop from './categories-icons/trending_top.svg'
import TwoPlayer from './categories-icons/two_player.svg'

// common icons
import Check from './icons/check.svg'
import ChevronDown from './icons/chevron-down.svg'
import ChevronL from './icons/chevron-l.svg'
import ChevronR from './icons/chevron-r.svg'
import ChevronUp from './icons/chevron-up.svg'
import Close from './icons/close.svg'
import Collapse from './icons/collapse.svg'
import FennekCircle from './icons/fennek-circle.svg'
import Fennek from './icons/fennek.svg'
import Fullscreen from './icons/fullscreen.svg'
import Hand from './icons/hand.svg'
import Help from './icons/help.svg'
import Home from './icons/home.svg'
import Key from './icons/key.svg'
import Loader from './icons/loader.svg'
import Logout from './icons/logout.svg'
import Menu from './icons/menu.svg'
import Play from './icons/play.svg'
import Report from './icons/report.svg'
import Sdk from './icons/sdk.svg'
import Send from './icons/send.svg'
import Share from './icons/share.svg'
import Timer from './icons/timer.svg'
import Trash from './icons/trash.svg'
import UserAdd from './icons/user-add.svg'
import Wallet from './icons/wallet.svg'
import FennekCoin from './icons/fennek-coin.png'

// Added new SVG — run `npx svgo -f src/components_new/Icon/icons`

const icons = {
    // category icons
    action: Action,
    adventure: Adventure,
    all_games: AllGames,
    applications: Applications,
    baby: Baby,
    baloons: Baloons,
    bejeweled: Bejeweled,
    boys: Boys,
    cards: Cards,
    casino: Casino,
    clicker: Clicker,
    complete_reg: CompleteReg,
    cook: Cook,
    economic: Economic,
    educational: Educational,
    farming: Farming,
    girls: Girls,
    horrors: Horrors,
    hypercasual: Hypercasual,
    imitations: Imitations,
    io: Io,
    kids: Kids,
    match3: Match3,
    midcore: Midcore,
    new: New,
    popular: Popular,
    purchase: Purchase,
    puzzle: Puzzle,
    quiz: Quiz,
    racing: Racing,
    random: Random,
    recommended: Recommended,
    role: Role,
    shooting: Shooting,
    simulator: Simulator,
    soccer: Soccer,
    social: Social,
    special: Special,
    sports: Sports,
    stickman: Stickman,
    strategy: Strategy,
    tabletop: Tabletop,
    tests: Tests,
    three_d: ThreeD,
    trending_top: TrendingTop,
    two_player: TwoPlayer,

    // common icons
    check: Check,
    chevron_down: ChevronDown,
    chevron_l: ChevronL,
    chevron_r: ChevronR,
    chevron_up: ChevronUp,
    close: Close,
    collapse: Collapse,
    fennek_circle: FennekCircle,
    fennek: Fennek,
    fullscreen: Fullscreen,
    hand: Hand,
    help: Help,
    home: Home,
    key: Key,
    loader: Loader,
    logout: Logout,
    menu: Menu,
    play: Play,
    report: Report,
    sdk: Sdk,
    send: Send,
    share: Share,
    timer: Timer,
    trash: Trash,
    user_add: UserAdd,
    wallet: Wallet,
    fennek_coin: FennekCoin,
}

const props = defineProps<{
    name: keyof typeof icons;
    alt?: string;
}>()

const icon = icons[props.name]

const altText = computed(() => props.alt || `${props.name} icon`)

</script>
<style module>
.icon {
    display: inline-block;
}

.img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}
</style>
