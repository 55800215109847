<template>
    <Layout>
        <main :class="$style.page">
            <div>
                <GameContainer
                    :game="game"
                    :isGameStarted="isGameStarted"
                    @startPlayingGame="startGame"
                    @openReportModal="openReportModal"
                />
                <GameBreadcrumbs :game="game"/>
            </div>
            <GameCarousel
                v-if="showRelatedGames"
                :title="t('relatedGames')"
                :games="relatedGames"
            />
            <GameDetails v-if="game" :game="game"/>
            <GameCarousel
                v-if="showSpotlightGames"
                :title="t('spotlight')"
                :games="spotlightGames"
                big
            />
            <GameCarousel
                v-if="showRecommendedGames"
                :title="t('recommendedGames')"
                :games="recommendedGames"
            />
            <GameCarousel
                v-if="showRandomGames"
                :title="t('random')"
                :games="randomGames"
            />
            <PromoCategories/>
            <TheEnd/>
            <ReportProblemModal
                v-if="isReportModalOpen"
                :isOpen="isReportModalOpen"
                :page="route.fullPath"
                @closeReportModal="closeReportModal"
            />
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import {
    onServerPrefetch,
    onMounted,
    onBeforeUnmount,
    ref,
    computed,
    watch,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { GameType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import { type PageStatus, useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useCategoriesStore } from '@/store/categories-store'
import { MainFrameService } from '@/modules/main-frame'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { sendEvent } from '@/utils/analytics'
import { useUserStore } from '@/store/user-store'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCarousel from '@/components_new/GameCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import ReportProblemModal from './components/ReportProblemModal.vue'
import GameContainer from './components_new/GameContainer.vue'
import GameBreadcrumbs from './components_new/GameBreadcrumbs.vue'
import GameDetails from './components_new/GameDetails.vue'
import { useGamePageStore } from './game-store'
import { generateHead } from './units'

const mainFrameService = ref<MainFrameService>()

const categoriesStore = useCategoriesStore()
const gamePageStore = useGamePageStore()
const appStore = useAppStore()
const adsStore = useAdsStore()
const userStore = useUserStore()
const route = useRoute()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})
const { game, recommendations } = storeToRefs(gamePageStore)

const isGameStarted = ref(false)
const isReportModalOpen = ref(false)

const relatedGames = computed(() => recommendations.value?.related)
const showRelatedGames = computed(() => (relatedGames.value ? relatedGames.value.length > 0 : true))
const spotlightGames = computed(() => recommendations.value?.top_playgama)
const showSpotlightGames = computed(() => (spotlightGames.value ? spotlightGames.value.length > 0 : true))
const recommendedGames = computed(() => recommendations.value?.recommended)
const showRecommendedGames = computed(() => (recommendedGames.value ? recommendedGames.value.length > 0 : true))
const randomGames = computed(() => recommendations.value?.random)
const showRandomGames = computed(() => (randomGames.value ? randomGames.value.length > 0 : true))

const pageMeta = computed(() => generateHead({ game }))

useHead(pageMeta)

function openReportModal() {
    isReportModalOpen.value = true
}

function closeReportModal() {
    isReportModalOpen.value = false
}

function startGame() {
    isGameStarted.value = true
    categoriesStore.updateViewedGame(game.value!.hru, game!.value as GameType)
    sendEvent({
        eventName: 'game_opened',
        userId: userStore.user ? userStore.user._id : undefined,
        clid: appStore.clid,
        pageName: route.name as string || undefined,
        gameHru: game.value?.hru,
    })
}

async function updateData(routeParams: string) {
    // Prevents the request from being made twice if the data is already in the store
    if (game.value?.hru !== routeParams) {
        isGameStarted.value = false
        gamePageStore.resetGameData()
        await gamePageStore.setGameWithRecommendations(routeParams)
    }
}

onServerPrefetch(async () => {
    try {
        await Promise.all([
            updateData(route.params.game as string),
            categoriesStore.setMainData(),
        ])
        if (game.value?.id === route.params.game && route.params.game !== game.value?.hru) {
            const search = route.fullPath.replace(/^[^?]+/i, '')
            appStore.setPageStatus(301)
            appStore.setTargetURL(`/game/${game.value.hru}${search}`)
        }
    } catch (e) {
        if (typeof e === 'number') {
            appStore.setPageStatus(e as PageStatus)
        } else {
            appStore.setPageStatus(503)
        }
    }
})

onMounted(async () => {
    if (!adsStore.adMediator) {
        console.error('Error: No ad service was passed to the main frame')
        return
    }
    mainFrameService.value = new MainFrameService({
        logger: window.dataLayer,
        adMediator: adsStore.adMediator,
    })
    Promise.all([
        updateData(route.params.game as string),
        categoriesStore.setMainData(),
    ])
})

watch(() => route.params.game, async () => {
    // when the game changes, the component is not re-mounted, but we need to update the data
    await updateData(route.params.game as string)
})

onBeforeUnmount(() => {
    gamePageStore.resetGameData()
    mainFrameService.value?.destroy()
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
</style>
<i18n lang="json">{
    "en": {
        "relatedGames": "Related",
        "spotlight": "Spotlight",
        "recommendedGames": "Recommended",
        "random": "Random"
    }
}
</i18n>
