export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
        "playGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Games"])},
        "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads"])},
        "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
        "friendsBannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Friends & Get Coins"])},
        "friendsBannerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your link, grow your pack!"])},
        "friendsBannerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
        "inviteBenefit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn 10k coins for each friend"])},
        "inviteBenefit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 5% of their earnings"])},
        "inviteBenefit3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock coin multipliers and rewards"])}
      }
    }
  })
}
