<template>
    <div v-if="show">
        <div id="g_id_onload"
             :data-client_id="VUE_APP_GAUTH_CLID"
             :data-login_uri="loginURL"
             data-auto_prompt="false"
        >
        </div>
        <div class="g_id_signin"
             data-type="standard"
             data-size="large"
             data-theme="outline"
             data-text="sign_in_with"
             data-shape="rectangular"
             data-logo_alignment="left">
        </div>
    </div>
</template>
<script lang="ts" setup>
import {
    computed, onBeforeMount, onMounted, ref,
} from 'vue'
import { useRoute } from 'vue-router'

const { VUE_APP_GAUTH_CLID, VUE_APP_GAUTH_REDIRECT } = process.env

const route = useRoute()

const loginURL = computed(() => `${VUE_APP_GAUTH_REDIRECT}?${new URLSearchParams({ returnURL: `${window.location.origin}${route.fullPath}` })}`)

onBeforeMount(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)
})

// show only on the client side
const show = ref(false)
onMounted(() => {
    show.value = true
})

</script>
