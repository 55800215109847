import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLayoutStore = defineStore('layout', () => {
    const fullScreen = ref(false)

    function setFullscreen(value: boolean) {
        fullScreen.value = value
    }

    const categoriesModalOpened = ref(false)

    function setCategoriesModalOpened(value: boolean) {
        categoriesModalOpened.value = value
    }

    return {
        setFullscreen,
        fullScreen,
        setCategoriesModalOpened,
        categoriesModalOpened,
    }
})
