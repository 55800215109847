<template>
    <component
        :is="link ? 'RouterLink' : 'button'"
        :to="link"
        :class="[
            $style.button,
            $style[`size_${props.size}`],
            $style[`width_${props.width}`],
            $style[`effect_${props.effect}`],
            $style[`visual_${props.visual}`],
            $style[`color_${props.color}`],
            props.disabled ? $style.disabled : '',
        ]"
        :type="props.type"
        :disabled="props.disabled"
        @click="emit('click', $event)"
    >
        <div :class="$style.content">
            <Icon v-if="props.iconLeft" :name="props.iconLeft" :class="$style.iconLeft"/>
            <slot />
            <Icon v-if="props.iconRight" :name="props.iconRight" :class="$style.iconRight"/>
            <Icon v-if="props.icon" :name="props.icon" :class="$style.iconCenter"/>
        </div>
    </component>
</template>

<script lang="ts" setup>
import {
    defineProps,
    defineEmits,
    withDefaults,
} from 'vue'
import { RouteLocationRaw } from 'vue-router'
import Icon from '@/components_new/Icon/Icon.vue'

interface ButtonProps {
    type?: 'button' | 'submit',
    disabled?: boolean,
    link?: string | RouteLocationRaw,
    size?: 's' | 'm' | 'l',
    width?: 'normal' | 'full',
    visual?: 'fill' | 'outline' | 'text',
    color?: 'purple' | 'golden' | 'white' | 'graphite'
    effect?: 'bevel' | 'no',
    iconLeft?: string, // TODO infer Icon props type
    iconRight?: string, // TODO infer Icon props type
    icon?: string, // TODO infer Icon props type
}

const props = withDefaults(defineProps<ButtonProps>(), {
    type: 'button',
    disabled: false,
    size: 'm',
    width: 'normal',
    visual: 'fill',
    color: 'purple',
    effect: 'no',
})
/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'click', value: Event): void}>()

</script>

<style module>
/* Color themes */

.color_purple {
    --text: #fff;
    --main: #9747FF;
    --main-outline: #C7ABFF;
}

.color_golden {
    --text: #000;
    --main: #FFC850;
}

.color_white {
    --text: #000;
    --main: #ffffff;
    --main-outline: rgba(255, 255, 255, 0.6);
}

.color_graphite {
    --text: #fff;
    --main: #29292E;
}

/* Base styles */

.button {
    display: inline-block;
    font-family: 'geist', sans-serif;
    font-weight: 500;
    line-height: 100%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    border-radius: var(--border-radius);
    --border-width: 2px;
    --border-radius: 1000px;

    --focus-accent: #C0FF47;
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.button:before, .button:after {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: var(--border-radius);
}

.button:before {
    background: var(--background-color, transparent);
}

.button:after {
    border: var(--border-width) solid var(--border-color, transparent);
}

.button:focus-visible {
    outline: none;
    --border-color: var(--focus-accent, var(--border));
}

.disabled {
    cursor: not-allowed;
}

/* Visuals */

.visual_fill {
    color: var(--text) !important; /* TODO check normalize.css for button color style */
    --background-color: var(--main);
}

.visual_fill:hover:before {
    filter: brightness(1.08)
}
.visual_fill:active:before {
    filter: brightness(0.92)
}

/* color-theme independent */
.visual_fill.disabled {
    color: rgba(255,255,255,.4);
    --background-color: #29292E;
}

.visual_outline {
    color: var(--main-outline);
    --border-color: var(--main-outline);
}

.visual_outline:hover,
.visual_outline:active {
    color: var(--text);
    --background-color: var(--main);
    --border-color: transparent;
}
.visual_outline:hover:before {
    filter: brightness(1.08)
}
.visual_outline:active:before {
    filter: brightness(0.92)
}

/* color-theme independent */
.visual_fill.disabled {
    color: rgba(255,255,255,.4);
    --border-color: #29292E;
}

.visual_text {
    color: var(--main-outline);
}

.visual_text:hover,
.visual_text:active {
    color: var(--text);
    --background-color: var(--main);
}
.visual_text:hover:before {
    filter: brightness(1.08)
}
.visual_text:active:before {
    filter: brightness(0.92)
}

/* color-theme independent */
.visual_text.disabled {
    color: rgba(255,255,255,.4);
}

/* Sizes */

.size_s {
    font-size: 12px;
    height: 24px;
    padding: 6px 8px;
    --border-radius: 12px;
}
.size_s .content {
    gap: 4px;
}
.size_s .iconLeft,
.size_s .iconRight {
    width: 12px;
    height: 12px;
}
.size_s .iconCenter {
    width: 16px;
    height: 16px;
}
.size_s:has(.iconCenter) {
    padding: 4px
}

.size_m {
    font-size: 14px;
    height: 32px;
    padding: 6px 12px;
    --border-radius: 16px;
}
.size_m .content {
    gap: 6px;
}
.size_m .iconLeft,
.size_m .iconRight,
.size_m .iconCenter {
    width: 20px;
    height: 20px;
}
.size_m:has(.iconLeft) {
    padding-left: 8px
}
.size_m:has(.iconRight) {
    padding-right: 8px
}
.size_m:has(.iconCenter) {
    padding: 6px
}

.size_l {
    font-size: 16px;
    height: 48px;
    padding: 12px 20px;
    --border-radius: 24px;
}
.size_l .content {
    gap: 8px;
}
.size_l .iconLeft,
.size_l .iconRight,
.size_l .iconCenter {
    width: 24px;
    height: 24px;
}
.size_l:has(.iconLeft) {
    padding-left: 12px;
    padding-right: 16px;
}
.size_l:has(.iconRight) {
    padding-right: 12px;
    padding-left: 16px;
}
.size_l:has(.iconCenter) {
    padding: 12px
}

/* Width */

.width_full {
    width: 100%;
}

/* Effects */

.effect_bevel:before {
    box-shadow:
        inset 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.32),
        0px 1.5px 1px 0px rgba(0, 0, 0, 0.32);
}
</style>
