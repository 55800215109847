<template>
    <div :class="$style.rotate" v-if="shouldRotate">
        <RotateIcon :class="$style.icon" />
        <p :class="$style.title">{{ t('rotate') }}</p>
        <p :class="$style.text">{{ t('message', { orientation: props.orientation }) }}</p>
    </div>
</template>

<script lang="ts" setup>
import {
    computed, defineProps, onMounted, onUnmounted, ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@/store/app-store'
import RotateIcon from './icons/rotate.svg'

interface RotateMessageProps {
    orientation: string
}

const props = defineProps<RotateMessageProps>()

const { t } = useI18n()

const { platform } = useAppStore()
const screenOrientation = ref('')
const shouldRotate = computed(() => {
    if (
        platform === 'desktop'
        || screenOrientation.value.includes(props.orientation)
    ) {
        return false
    }
    return true
})

const onOrientationChange = () => {
    screenOrientation.value = window.screen.orientation.type
}

onMounted(() => {
    if (platform !== 'desktop') {
        window.screen.orientation.addEventListener('change', onOrientationChange)
        screenOrientation.value = window.screen.orientation.type
    }
})

onUnmounted(() => {
    window.screen.orientation.removeEventListener('change', onOrientationChange)
})
</script>

<style module>
.rotate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.icon {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 32px;
}

.title {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 12px;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
}
</style>

<i18n lang="json">{
    "en": {
        "rotate": "Rotate phone",
        "message": "The game supports only { orientation } orientation"
    }
}</i18n>
