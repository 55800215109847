export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "p2e_coin_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
        "p2e_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily rewards"])},
        "p2e_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play, earn, and spend coins.<br>Sign up for free and start earning for real drops of TON"])},
        "btn_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login & Claim"])},
        "btn_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games categories"])},
        "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
        "advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisers"])},
        "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishers"])}
      }
    }
  })
}
