<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
    <header :class="$style.header">
        <div :class="$style.scroller">
            <template v-for="(item) in menuCategoriesList" :key="item.name">
                <RouterLink v-tooltip="{ content: t(item.name), theme: 'common-tooltip'}" :to="item.href" :class="$style.item">
                    <Icon :name="category2icon[item.name]" :alt="t(item.name)" :class="$style.item_icon"/>
                </RouterLink>
            </template>
        </div>
    </header>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import { categoriesTranslation } from '@/utils/translations/categories'
import Icon from '@/components_new/Icon/Icon.vue'
import { category2icon } from '@/components_new/Icon/categories'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const categoriesStore = useCategoriesStore()

const {
    menuCategoriesList,
} = storeToRefs(categoriesStore)

</script>
<style module>
.header {
    height: 100%;
    overflow-x: clip;
    position: relative;
    display: flex;
    align-items: center;
    --edge-shadow-width: 20px;
    --first-elem-offset: var(--edge-shadow-width);
    margin-left: calc(var(--first-elem-offset) * -1);
    margin-right: calc(var(--global-wide-scroller-padding-right) * -1);
}

.header:before, header:after {
    content: '';
    position: absolute;
    height: 100%;
    width: var(--edge-shadow-width);
    top: 0;
    background: linear-gradient(to left, transparent, #000);
}
header:before {
    display: none; /* temporarily, right now there is no scroll */
}
header:after {
    right: 0;
    background: linear-gradient(to right, transparent, #000);
}

.header:hover, .header:focus-within {
    z-index: 1;
    /* extend visible area, so first element with focus and tooltip is not cropped by overflow-x: clip */
    --first-elem-offset: 50px;
}

.scroller {
    --item-size: 24px;
    height: var(--item-size);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(calc(var(--item-size) + 10px), calc(var(--item-size) + 30px));
    margin-left: var(--first-elem-offset);
    margin-right: var(--edge-shadow-width);
    flex-grow: 1;
}

.item {
    color: #C7ABFF;
    display: flex;
    justify-content: center;
    will-change: transform;
    transition: transform 0.15s ease-out;
    position: relative;
    --hover-shift: 10px;
}
.item:before {
    content: '';
    position: absolute;
    inset: calc(var(--hover-shift) * -0.5)
}
.item_icon {
    will-change: transform;
    transition: transform 0.15s ease-out;
}

.item:hover,
.item:focus-visible {
    color: #C0FF47;
    outline: none;
}
.item:hover .item_icon,
.item:focus-visible .item_icon {
    transform: scale(2);
}

.item:has(~ .item:hover),
.item:has(~ .item:focus-visible) {
    transform: translate(calc(var(--hover-shift) * -1), 0);
}

.item:has(+ .item:hover) .item_icon,
.item:has(+ .item:focus-visible) .item_icon {
    transform: scale(1.5);
}

.item:hover~.item,
.item:focus-visible~.item {
    transform: translate(var(--hover-shift), 0);
}

.item:hover+.item .item_icon,
.item:focus-visible+.item .item_icon {
    transform: scale(1.5);
}
</style>
