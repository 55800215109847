<template>
    <div :class="$style.sidebar">
        <Logo :class="$style.logo" />
        <div :class="$style.sections">
            <div v-if="isUserInfoLoaded" :class="$style.section">
                <div v-if="isAuthorized && user" :class="$style.auth_view">
                    <RouterLink to="/profile" :class="[$style.auth_user, $style.links_item]">
                        <UserAvatar
                            :class="$style.avatar"
                            :avatar="user.avatar"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                        />
                        <span :class="$style.user_name">{{ `${user.firstName} ${user.lastName}` }}</span>
                        <span :class="$style.spacer"/>
                        <Icon :class="$style.chevron" name="chevron_r" />
                    </RouterLink>
                    <span :class="$style.inner_sep"></span>
                    <RouterLink to="/profile" :class="$style.p2e_auth">
                        <Icon name="fennek_coin"/>
                        <span :class="$style.p2e_auth_label">{{ fennecsLabel }}</span>
                    </RouterLink>
                </div>
                <div v-else :class="$style.p2e">
                    <img :class="$style.p2e_coin" :alt="t('p2e_coin_alt')" :src="coinImg"/>
                    <div :class="$style.p2e_texts">
                        <h6 :class="$style.p2e_title">{{ t('p2e_title') }}</h6>
                        <span :class="$style.p2e_desc" v-html="t('p2e_desc')"></span>
                    </div>
                    <Button size="l" width="full" effect="bevel" @click="signin" color="golden">{{ t('btn_login') }}</Button>
                </div>
            </div>
            <button :class="[$style.section, $style.links_item, $style.categories]" @click="openCategories">
                {{ t('btn_categories') }}
                <Icon :class="$style.chevron" name="chevron_r" />
            </button>
            <div :class="$style.section">
                <ul :class="$style.links">
                    <li v-for="(item, index) in items" :key="item.title">
                        <span v-if="index !== 0" :class="$style.inner_sep"></span>
                        <a v-if="item.external" :href="item.link" :class="$style.links_item">
                            {{ item.title }}
                        </a>
                        <RouterLink v-else-if="item.link" :to="item.link" :class="$style.links_item">
                            {{ item.title }}
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
} from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { useUserStore } from '@/store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import { useAppStore } from '@/store/app-store'
import Logo from '@/components_new/Logo.vue'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import coinImg from './images/coin.png'

const { t } = useI18n()

const items = [
    {
        title: t('developers'),
        link: 'https://developer.playgama.com/about',
        external: true,
    },
    {
        title: t('advertisers'),
        link: '/advertisers',
    },
    {
        title: t('publishers'),
        link: '/publishers',
    },
]

const { setCategoriesModalOpened } = useLayoutStore()

function openCategories() {
    setCategoriesModalOpened(true)
}

const userStore = useUserStore()
const {
    user, isAuthorized, fennecsLabel, isUserInfoLoaded,
} = storeToRefs(userStore)

const { setIsAuthUserModalOpen } = useAppStore()

function signin() {
    setIsAuthUserModalOpen(true)
}
</script>

<style module>
.sidebar {
    display: flex;
    flex-direction: column;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
}

.logo {
    margin: 0 auto;
    height: 88px;
    box-sizing: border-box;
    padding: 24px 0;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.section {
    border-radius: 16px;
    background: #17171a;
}

.links {
    padding: 4px 12px;
}

.links_item {
    display: block;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.6);

    /* sometimes .sidebar styles has less priority, for button for example */
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
}
.links_item:hover {
    color: rgba(255, 255, 255, 1);
}

.chevron {
    height: 16px;
}

.inner_sep {
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.08);
}

.categories {
    padding: 20px 10px 20px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.spacer {
    flex-grow: 1
}

.auth_view {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
}

.auth_user {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
}

.auth_user > * {
    flex-shrink: 0;
}

.user_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    color: #fff;
}

.p2e_auth {
    display: flex;
    align-items: center;
    gap: 8px;
}

.p2e_auth > * {
    flex-shrink: 0;
}

.p2e_auth_label {
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
}

.p2e {
    display: flex;
    gap: 24px;
    padding: 24px 12px;
    flex-direction: column;
    align-items: center;
}
.p2e_coin {
    width: 100px;
    height: 100px;
}
.p2e_texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.p2e_title {
    font-size: 23px;
    font-weight: 600;
}
.p2e_desc {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);

    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
}

</style>
<i18n lang="json">{
    "en": {
        "p2e_coin_alt": "Coin",
        "p2e_title": "Daily rewards",
        "p2e_desc": "Play, earn, and spend coins.<br>Sign up for free and start earning for real drops of TON",
        "btn_login": "Login & Claim",
        "btn_categories": "Games categories",
        "developers": "Developers",
        "advertisers": "Advertisers",
        "publishers": "Publishers"
    }
}</i18n>
