<template>
    <div :class="$style.container">
        <template v-if="props.link">
            <h2 v-if="props.title" :class="$style.title">
                <RouterLink :to="props.link" tabindex="-1">
                    {{ props.title }}
                </RouterLink>
                <Button
                    icon="chevron_r"
                    color="graphite"
                    size="s"
                    :link="props.link"
                    :class="$style.title_button"
                />
            </h2>
        </template>
        <h2 v-else-if="props.title" :class="$style.title">
            {{ props.title }}
        </h2>
        <div :class="$style.scroller_wrap">
            <div
                :class="[
                    $style.scroller,
                    props.big ? $style.scroller_big : $style.scroller_small,
                    props.games ? undefined : $style.scroller_empty
                ]"
                @scroll="onScroll" ref="scrollerRef"
            >
                <GameCard
                    v-for="(game, index) in items"
                    :key="index"
                    :game="game"
                    :appearance="itemIsBig(index) ? 'big' : 'medium'"
                    responsive
                    :class="[$style.card, itemIsBig(index) && $style.card_big]"
                />
                <Button
                    v-if="props.lastItemLink"
                    icon="chevron_r"
                    color="graphite"
                    size="l"
                    :link="props.lastItemLink"
                    :class="[$style.card, $style.card_next]"
                />
            </div>
            <button
                tabindex="-1"
                :class="[$style.arrow, leftArrowActive && $style.arrow_active, $style.arrow_left]"
                @click="onArrowLeftClick"
            >
                <Icon name="chevron_l"/>
            </button>
            <button
                tabindex="-1"
                :class="[$style.arrow, rightArrowActive && $style.arrow_active, $style.arrow_right]"
                @click="onArrowRightClick"
            >
                <Icon name="chevron_r"/>
            </button>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {
    computed, onMounted,
    defineProps, ref, useCssModule,
} from 'vue'
import { RouterLink } from 'vue-router'
import { useResizeObserver, useMutationObserver } from '@vueuse/core'
import GameCard from '@/components_new/GameCard.vue'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { SimplifiedGame } from '@/types'

interface GameCarouselProps {
    title?: string,
    link?: string,
    games?: SimplifiedGame[]
    big?: boolean
    firstItemBig?: boolean
    lastItemLink?: string
}

const props = defineProps<GameCarouselProps>()
const classes = useCssModule()

const items = computed(() => props.games || Array(20).fill(null))

function itemIsBig(index: number): boolean {
    if (props.big || (props.firstItemBig && index === 0)) {
        return true
    }
    return false
}

const scrollerRef = ref<HTMLElement | null>(null)
const leftArrowActive = ref(false)
const rightArrowActive = ref(false)

function onScroll(e: { currentTarget: unknown | null }) {
    const scroller = e.currentTarget
    if (!(scroller instanceof HTMLElement)) return
    const notEmpty = !!props.games
    leftArrowActive.value = notEmpty && scroller.scrollLeft > 0
    rightArrowActive.value = notEmpty && scroller.scrollLeft + scroller.clientWidth + 1 < scroller.scrollWidth
}

function getScrollStepSize() {
    const cardClass = classes.card
    const card = scrollerRef.value?.querySelector(`.${cardClass}`)
    if (!card) return 0
    const computedStyle = getComputedStyle(card)
    const cardWidth = parseInt(computedStyle.getPropertyValue('width'), 10)
    let cardsCount = parseInt(computedStyle.getPropertyValue('--count'), 10)
    if (props.big) {
        cardsCount /= 2
    }
    const gap = parseInt(computedStyle.getPropertyValue('--gap'), 10)

    return cardsCount * cardWidth + (cardsCount - 1) * gap
}

function onArrowLeftClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.max(0, scroller.scrollLeft - scrollSize),
            behavior: 'smooth',
        })
    }
}
function onArrowRightClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.min(scroller.scrollWidth - scroller.clientWidth, scroller.scrollLeft + scrollSize),
            behavior: 'smooth',
        })
    }
}

useResizeObserver(scrollerRef, () => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

useMutationObserver(scrollerRef, () => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
}, {
    childList: true,
})

onMounted(() => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

</script>
<style module>
.container {
    --scroller-arrow-size: 40px;
}

.title {
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 20px;
}

.title_button {
    margin-left: 8px;
    opacity: 0;
    transition: opacity .1s ease;
}

.container:hover .title_button,
.container:focus-within .title_button {
    opacity: 1;
}

@media (hover: none) {
    .title_button {
        opacity: 1;
    }
}

.scroller_wrap {
    position: relative;
    /* crop arrows when they are hidden */
    overflow: hidden;

    margin-left: calc(var(--global-wide-scroller-padding-left) * -1);
    margin-right: calc(var(--global-wide-scroller-padding-right) * -1);
}

.arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--scroller-arrow-size);
    padding: 0 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    --transition-duration: .15s;
    --transition-fn: ease-in-out;
    transition:
        var(--transition-duration) var(--transition-fn) opacity,
        var(--transition-duration) var(--transition-fn) transform,
        0s var(--transition-fn) visibility var(--transition-duration);
}

.arrow_left {
    left: 0;
    transform: translateX(-10px);
    background: linear-gradient(to right, #0B0B0C, transparent);
}

.arrow_right {
    right: 0;
    transform: translateX(10px);
    background: linear-gradient(to left, #0B0B0C, transparent);
}

.arrow_active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
    transition:
        var(--transition-duration) var(--transition-fn) opacity,
        var(--transition-duration) var(--transition-fn) transform,
        0s var(--transition-fn) visibility;
}

.scroller {
    overflow: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scroll-padding: var(--scroller-arrow-size);
    scrollbar-width: none;

    padding-left: var(--global-wide-scroller-padding-left);
    padding-right: var(--global-wide-scroller-padding-right);

    /* base-size вычисляется так, чтобы на экране было --count обычных карточек + половинка еще одна */
    /* но не меньше 120 и не больше 220px */
    --base-size: clamp(
        120px,
        calc((100% - var(--gap) * var(--count)) / (var(--count) + 0.5)),
        220px
    );
    /* финальный размер карточки */
    --size: var(--base-size);
    --rows: 2;
    --gap: 8px;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: var(--size);
    grid-template-rows: repeat(var(--rows), 1fr);
    gap: var(--gap);
    --cards-gap: 8; /* unitless, consumed by GameCard component */
}

.scroller_big {
    --rows: 1;
}

.scroller::-webkit-scrollbar {
  display: none;
}

.scroller_empty {
    overflow: hidden;
}

/* Custom widths for specific number of visible cards, considers layout columns jumps */

.scroller {
    --count: 2;
}
@media (width >= 468px) {
    .scroller {
        --count: 3;
    }
}
@media (width >= 600px) {
    .scroller {
        --count: 4;
    }
}
@media (width >= 1300px) {
    .scroller {
        --count: 5;
    }
}
@media (width >= 1550px) {
    .scroller {
        --count: 6;
    }
}
@media (width >= 1825px) {
    .scroller {
        --count: 7;
    }
}

.card {
    scroll-snap-align: start;
    scroll-snap-stop: normal;
}

.card_big {
    grid-column: span 2;
    grid-row: span var(--rows);
}

/* make heavier selector to override button's styles, TODO refactor */
.scroller .card_next {
    height: auto;
    --border-radius: 12px;
    grid-row: span var(--rows);
}
</style>
