export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
        "playNoAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Play"])},
        "playAndEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play & Earn"])},
        "continue_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue play"])},
        "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
        "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
        "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullscreen"])},
        "exit_fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit game"])}
      }
    }
  })
}
