<template>
    <ul :class="$style.breadcrumbs">
        <li v-for="(item, index) in items" :key="item.title">
            <span v-if="index !== 0" :class="$style.sep">/</span>
            <component :is="item.link ? 'RouterLink' : 'span'" :to="item.link" :class="$style.item"
                       :style="{ flexShrink: item.shrink }">
                {{ item.title }}
            </component>
        </li>
    </ul>
</template>
<script lang="ts" setup>
import { computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import { GameType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'

const props = defineProps<{
    game?: GameType
}>()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const items = computed(() => {
    const category = props.game?.categories[0]
    return [{
        title: t('home'),
        link: '/',
        shrink: 1,
    },
    category && {
        title: t(category),
        link: `/category/${category}`,
        shrink: 1,
    },
    props.game && {
        title: props.game.title,
        shrink: 0,
    },
    ].filter(Boolean)
})

</script>
<style module>
.breadcrumbs {
    padding: 8px 0;
    border-bottom: 1px solid #17171A;
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    display: flex;
}

.breadcrumbs li {
    display: contents;
}

.sep,
.item {
    padding: 4px 2px;
    display: inline-block;
}

.item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.item:hover {
    color: #fff;
}
</style>
<i18n lang="json">{
    "en": {
        "home": "Playgama",
        "sep": "/"
    }
}</i18n>
